import { IS_DEVELOPMENT_ENV, IS_PRODUCTION_ENV, IS_TEST_ENV, USE_LOCAL_BE } from './EnvConfig';

export const HTMLEditorApiKey = 'auolhv0aeh882fj8twntv4kbcgh328qauwjx9u8mrfzizwjz';
export const LogrocketLoggingEnabled = false;
export const requiredKeycloakRoles = ['diremouser'];

export enum ServiceName {
  Diremo = 'diremo',
  Message = 'message',
  Serviceadmin = 'serviceadmin',
  Form = 'form',
  Query = 'query',
  Identity = 'identity',
  Document = 'document',
  Notes = 'notes',
  RuntimeBundle1 = 'runtime-bundle-1',
}

type ServiceConfig = {
  name: ServiceName;
  url: string;
};

const localDevRestApiURL: Record<ServiceName, ServiceConfig> = {
  [ServiceName.Diremo]: { name: ServiceName.Diremo, url: 'http://192.168.100.100:8090/redimo/' },
  [ServiceName.Serviceadmin]: {
    name: ServiceName.Serviceadmin,
    url: 'http://192.168.100.100:8060/diremo-serviceadmin/',
  },
  [ServiceName.Message]: {
    name: ServiceName.Message,
    url: 'http://192.168.100.100:8070/diremo-message/',
  },
  [ServiceName.Form]: { name: ServiceName.Form, url: 'http://192.168.100.100:8085/diremo-form/' },
  [ServiceName.Query]: {
    name: ServiceName.Query,
    url: 'http://192.168.100.100:8082/activiti-cloud-query/',
  },
  [ServiceName.Identity]: {
    name: ServiceName.Identity,
    url: 'http://192.168.100.100:8084/identity-adapter-service/',
  },
  [ServiceName.Document]: {
    name: ServiceName.Document,
    url: 'http://192.168.100.100:8095/diremo-document/',
  },
  [ServiceName.Notes]: {
    name: ServiceName.Notes,
    url: 'http://192.168.100.100:8092/diremo-notes/',
  },
  [ServiceName.RuntimeBundle1]: {
    name: ServiceName.RuntimeBundle1,
    url: 'http://192.168.100.100:8050/',
  },
};

const deployedDevRestApiURL: Record<ServiceName, ServiceConfig> = {
  [ServiceName.Diremo]: {
    name: ServiceName.Diremo,
    url: 'https://diremoutv.afbostader.se/redimo/',
  },
  [ServiceName.Serviceadmin]: {
    name: ServiceName.Serviceadmin,
    url: 'https://diremoutv.afbostader.se/diremo-serviceadmin/',
  },
  [ServiceName.Message]: {
    name: ServiceName.Message,
    url: 'https://diremoutv.afbostader.se/diremo-message/',
  },
  [ServiceName.Form]: {
    name: ServiceName.Form,
    url: 'https://diremoutv.afbostader.se/diremo-form/',
  },
  [ServiceName.Query]: {
    name: ServiceName.Query,
    url: 'https://diremoutv.afbostader.se/activiti-cloud-query/',
  },
  [ServiceName.Identity]: {
    name: ServiceName.Identity,
    url: 'https://diremoutv.afbostader.se/identity-adapter-service/',
  },
  [ServiceName.Document]: {
    name: ServiceName.Document,
    url: 'https://diremoutv.afbostader.se/diremo-document/',
  },
  [ServiceName.Notes]: {
    name: ServiceName.Notes,
    url: 'https://diremoutv.afbostader.se/diremo-notes/',
  },
  [ServiceName.RuntimeBundle1]: {
    name: ServiceName.RuntimeBundle1,
    url: 'https://diremoutv.afbostader.se/',
  },
};

const devBaseRestApiURL = USE_LOCAL_BE ? localDevRestApiURL : deployedDevRestApiURL;

const prodBaseRestApiURL: Record<ServiceName, ServiceConfig> = {
  [ServiceName.Diremo]: { name: ServiceName.Diremo, url: 'https://diremo.afbostader.se/redimo/' },
  [ServiceName.Serviceadmin]: {
    name: ServiceName.Serviceadmin,
    url: 'https://diremo.afbostader.se/diremo-serviceadmin/',
  },
  [ServiceName.Message]: {
    name: ServiceName.Message,
    url: 'https://diremo.afbostader.se/diremo-message/',
  },
  [ServiceName.Form]: { name: ServiceName.Form, url: 'https://diremo.afbostader.se/diremo-form/' },
  [ServiceName.Query]: {
    name: ServiceName.Query,
    url: 'https://diremo.afbostader.se/activiti-cloud-query/',
  },
  [ServiceName.Identity]: {
    name: ServiceName.Identity,
    url: 'https://diremo.afbostader.se/identity-adapter-service/',
  },
  [ServiceName.Document]: {
    name: ServiceName.Document,
    url: 'https://diremo.afbostader.se/diremo-document/',
  },
  [ServiceName.Notes]: {
    name: ServiceName.Notes,
    url: 'https://diremo.afbostader.se/diremo-notes/',
  },
  [ServiceName.RuntimeBundle1]: {
    name: ServiceName.RuntimeBundle1,
    url: 'https://diremo.afbostader.se/',
  },
};

const testBaseRestApiURL: Record<ServiceName, ServiceConfig> = {
  [ServiceName.Diremo]: {
    name: ServiceName.Diremo,
    url: 'https://diremotest.afbostader.se/redimo/',
  },
  [ServiceName.Serviceadmin]: {
    name: ServiceName.Serviceadmin,
    url: 'https://diremotest.afbostader.se/diremo-serviceadmin/',
  },
  [ServiceName.Message]: {
    name: ServiceName.Message,
    url: 'https://diremotest.afbostader.se/diremo-message/',
  },
  [ServiceName.Form]: {
    name: ServiceName.Form,
    url: 'https://diremotest.afbostader.se/diremo-form/',
  },
  [ServiceName.Query]: {
    name: ServiceName.Query,
    url: 'https://diremotest.afbostader.se/activiti-cloud-query/',
  },
  [ServiceName.Identity]: {
    name: ServiceName.Identity,
    url: 'https://diremotest.afbostader.se/identity-adapter-service/',
  },
  [ServiceName.Document]: {
    name: ServiceName.Document,
    url: 'https://diremotest.afbostader.se/diremo-document/',
  },
  [ServiceName.Notes]: {
    name: ServiceName.Notes,
    url: 'https://diremotest.afbostader.se/diremo-notes/',
  },
  [ServiceName.RuntimeBundle1]: {
    name: ServiceName.RuntimeBundle1,
    url: 'https://diremotest.afbostader.se/',
  },
};

const labBaseRestApiURL: Record<ServiceName, ServiceConfig> = {
  [ServiceName.Diremo]: { name: ServiceName.Diremo, url: '/redimo/' },
  [ServiceName.Serviceadmin]: { name: ServiceName.Serviceadmin, url: '/diremo-serviceadmin/' },
  [ServiceName.Message]: { name: ServiceName.Message, url: '/diremo-message/' },
  [ServiceName.Form]: { name: ServiceName.Form, url: '/diremo-form/' },
  [ServiceName.Query]: { name: ServiceName.Query, url: '/activiti-cloud-query/' },
  [ServiceName.Identity]: { name: ServiceName.Identity, url: '/identity-adapter-service/' },
  [ServiceName.Document]: { name: ServiceName.Document, url: '/diremo-document/' },
  [ServiceName.Notes]: { name: ServiceName.Document, url: '/diremo-notes/' },
  [ServiceName.RuntimeBundle1]: { name: ServiceName.RuntimeBundle1, url: '/' },
};

export const baseRestApiURL = IS_PRODUCTION_ENV
  ? prodBaseRestApiURL
  : IS_TEST_ENV
  ? testBaseRestApiURL
  : IS_DEVELOPMENT_ENV
  ? devBaseRestApiURL
  : labBaseRestApiURL;

export const getServiceUrl = (serviceName: ServiceName | string): string => {
  const service = baseRestApiURL[serviceName as ServiceName];
  if (!service) {
    return baseRestApiURL[ServiceName.RuntimeBundle1].url + serviceName + '/';
  }
  return service.url;
};

export const getServiceName = (name: `${ServiceName}`): ServiceName => {
  return name as ServiceName;
};
