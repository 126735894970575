import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SliceBaseState } from 'helpers/types';
import { http } from './fetcher';
import { RootState } from 'store/store';
import { isArray } from 'helpers/utils';
import { ServiceName } from 'configs/appConfig';

// Definiera UserResponse för det nya API:et
export type KeycloakUserResponse = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
};

type UserLookupData = Omit<KeycloakUserResponse, 'username'>;
export type KeycloakUserDetailLookup = Record<string, UserLookupData>;

// Redux-staten
interface UserDetails extends SliceBaseState {
  lookup: KeycloakUserDetailLookup;
  list: KeycloakUserResponse[];
}

const initialState: UserDetails = {
  status: 'idle',
  lookup: {},
  list: [],
};

// Metod för att hämta data
export const fetchKeycloakUserDetailsAsync = createAsyncThunk(
  'keycloakUserDetails/fetch',
  async () => {
    const response = await http.get<KeycloakUserResponse[]>(
      'users',
      undefined,
      'v1',
      ServiceName.Identity
    );

    // Kontrollera typen på datan, kaster fel om svaret inte matchar
    if (isArray(response.data)) {
      console.log(response);
      return response.data as KeycloakUserResponse[]; // Explicit typ-cast här
    }

    throw new Error('Felaktig data: Responsen är inte en array');
  }
);

const keycloakUserDetailsSlice = createSlice({
  name: 'keycloakUserDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeycloakUserDetailsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchKeycloakUserDetailsAsync.fulfilled, (state, action) => {
        state.status = 'idle';

        // Reducera bara om vi vet att datan är korrekt
        state.list = action.payload; // Här är action.payload korrekt typad till UserResponse[]
        state.lookup = action.payload.reduce<KeycloakUserDetailLookup>((acc, user) => {
          // Kontrollera typen av user manuellt
          if (user && typeof user === 'object' && 'username' in user) {
            acc[user.username] = {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
            };
          }
          return acc;
        }, {});
      })
      .addCase(fetchKeycloakUserDetailsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Exportera reducer och selectors
export default keycloakUserDetailsSlice.reducer;

export const selectKeycloakUserDetailsList = (state: RootState) => {
  return state.keycloakUserDetails.list;
};

export const selectKeycloakUserDetailsLookup = (state: RootState) => {
  return state.keycloakUserDetails.lookup;
};

export const selectKeycloakUserDetails = (state: RootState) => (username: string | null) => {
  return username ? state.keycloakUserDetails.lookup[username] : undefined;
};
